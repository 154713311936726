var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, width: "30%", center: "" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              _vm.dialogVisible = false
            },
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "detail-item" }, [
              _c("span", { staticClass: "detail-item-desc" }, [
                _vm._v("发送对象："),
              ]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    "共" +
                      _vm.formInline.mobileCount +
                      "条，其中" +
                      _vm.formInline.notMemberMobileCount +
                      "条非会员"
                  )
                ),
              ]),
              _c("span", { staticClass: "detail-item-tip" }, [
                _vm._v("*短信内容如果含参数，非会员将接收不到。"),
              ]),
              _c("p", { staticClass: "detail-item-mobiles" }, [
                _vm._v(_vm._s(_vm.formInline.mobiles)),
              ]),
            ]),
            _c("div", { staticClass: "detail-item" }, [
              _c("span", { staticClass: "detail-item-desc" }, [
                _vm._v("模板："),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.formInline.templateName))]),
            ]),
            _c("div", { staticClass: "detail-item" }, [
              _c("span", { staticClass: "detail-item-desc" }, [
                _vm._v(" 内容："),
              ]),
              _c("p", { staticClass: "detail-item-content" }, [
                _vm._v(" " + _vm._s(_vm.formInline.content) + " "),
              ]),
            ]),
            _c("div", { staticClass: "detail-item" }, [
              _c("span", { staticClass: "detail-item-desc" }, [
                _vm._v("任务类型："),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.formInline.taskTypeDesc))]),
            ]),
            _c("div", { staticClass: "detail-item" }, [
              _c("span", { staticClass: "detail-item-desc" }, [
                _vm._v("发送时间："),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.formInline.sendTime))]),
            ]),
            _c("div", { staticClass: "detail-item" }, [
              _c("span", { staticClass: "detail-item-desc" }, [
                _vm._v("发送状态："),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.formInline.taskStatusDesc))]),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 认")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }